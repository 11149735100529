import 'react-table/react-table.css';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';

import { Label, LargeSectionTitle } from '@animoto/components';
import Uppy from '@uppy/core';
import { DragDrop, StatusBar } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTable from 'react-table';

import AnimotoLayoutSentryWrapped from '../components/AnimotoLayout';
import {
  dragDrop,
  mediaLibraryBody,
  mediaLibraryTitle,
  wrap
} from './media-library.module.css';

export default class MediaLibraryPage extends Component {
  constructor(props) {
    super(props);
    const { data: { contentfulMediaLibrary } } = this.props;
    this.state = {
      data       : contentfulMediaLibrary,
      isNewMedia : false
    };
    this.uppy = Uppy({
      restrictions : { maxNumberOfFiles : null },
      autoProceed  : true
    });
    this.uppy.use(XHRUpload, {
      endpoint   : 'https://upload-integration.animoto.com/files/cms?info[s3_bucket]=assets.animoto.com&transparent=true',
      fieldName  : 'file[file]',
      metaFields : ['name']
    });
    this.uppy.on('complete', (result) => {
      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
      }

      const completeUpload = result.successful;
      const successfulUpload = completeUpload.map((successfulResultArray) => ({
        name     : successfulResultArray.name,
        fileSize : formatBytes(successfulResultArray.size),
        fileType : successfulResultArray.type,
        url      : successfulResultArray.response.body.response.payload.asset.links.original.replace('s3.amazonaws.com/assets.animoto.com', 'd2of6bhnpl91ni.cloudfront.net')
      }));

      const { data: { media } } = this.state;
      const mediaCopy = successfulUpload.concat(media);

      this.setState({
        data : {
          media : mediaCopy
        }
      });
      if (successfulUpload) {
        this.setState({ isNewMedia : true });
        fetch('/media-library/add', {
          method  : 'POST',
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify(successfulUpload)
        });
      }
    });
  }

  render() {
    const { data: { media } } = this.state;

    return (
      <AnimotoLayoutSentryWrapped pageTitle="Media Library">
        <div className={wrap}>
          <LargeSectionTitle className={mediaLibraryTitle}>
            Media Library Page
          </LargeSectionTitle>
          <div className={dragDrop}>
            <DragDrop
              locale={{
                strings : {
                  // Text to show on the droppable area.
                  // `%{browse}` is replaced with a link that opens the system file selection dialog.
                  dropHereOr : 'Drop here or %{browse}',
                  // Used as the label for the link that opens the system file selection dialog.
                  browse     : 'browse'
                }
              }}
              uppy={this.uppy}
            />
          </div>
          <div>
            <StatusBar
              hideAfterFinish={false}
              hideUploadButton
              showProgressDetails
              uppy={this.uppy}
            />
          </div>
          <div className={mediaLibraryBody}>
            <ReactTable
              className="-striped -highlight"
              columns={[
                {
                  Header   : () => <Label>Name</Label>,
                  accessor : 'name',
                  style    : { whiteSpace : 'unset' },
                  show     : true,
                  maxWidth : 350
                },
                {
                  Header   : () => <Label>URL</Label>,
                  accessor : 'url',
                  show     : true
                },
                {
                  Header   : () => <Label>File Type</Label>,
                  accessor : 'fileType',
                  show     : true,
                  maxWidth : 150,
                  Filter   : () => <div />
                },
                {
                  Header   : () => <Label>File Size</Label>,
                  accessor : 'fileSize',
                  show     : true,
                  maxWidth : 150,
                  Filter   : () => <div />
                }
              ]}
              data={media.reverse()}
              defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value)}
              defaultPageSize={10}
              filterable
              minRows={0}
            />
          </div>
        </div>
      </AnimotoLayoutSentryWrapped>
    );
  }
}

export const pageQuery = graphql`
  query {
    contentfulMediaLibrary {
        media {
          name
          url
          fileType
          fileSize
        }
    }
  }
`;

MediaLibraryPage.propTypes = {
  data : PropTypes.shape({
    contentfulMediaLibrary : PropTypes.shape({
      media : PropTypes.arrayOf(PropTypes.shape({
        fileSize : PropTypes.string,
        fileType : PropTypes.string,
        name     : PropTypes.string,
        url      : PropTypes.string
      }))
    })
  }).isRequired
};
